<script lang="ts">
  import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownDivider,
  } from "flowbite-svelte"

  import { FileQuestion, ChevronDown } from "lucide-svelte"
  import { type Org, selected_org } from "$lib/stores/selected_org"
  import InstallGithubAppModal from "./install-github-app-modal.svelte"
  import { goto } from "$app/navigation"

  type Props = {
    user_orgs: Org[]
    sidebar?: boolean
  }
  let { user_orgs, sidebar = false }: Props = $props()

  if (
    user_orgs.length > 0 &&
    user_orgs.every((x) => x.uuid !== $selected_org.uuid)
  ) {
    selected_org.set(user_orgs[0])
  }

  let org_dropdown_open = $state(false)
  let new_org_modal_open = $state(false)

  function onOrgSelection(org: Org) {
    return (evt: MouseEvent) => {
      evt.preventDefault()
      selected_org.set(org)
      org_dropdown_open = false
      // if (!sidebar) {
      //   setTimeout(() => goto("/console/overview"), 0)
      // }
    }
  }

  function onNewOrgRequested(evt: MouseEvent) {
    evt.preventDefault()
    org_dropdown_open = false
    new_org_modal_open = true
  }

  const btnClass = sidebar
    ? "px-2 sm:px-4 py-1 w-full text-left justify-start justify-items-start sm:justify-items-center"
    : "py-1 min-w-32"

  const btnTextClass = sidebar ? "ml-2 flex-1 hidden sm:inline" : "ml-2 flex-1"

  const btnChevronClass =
    "w-fit h-4 ms-2 text-dark dark:text-white justify-self-end"
</script>

{#if user_orgs.length > 0}
  <Button size="sm" color="alternative" class={btnClass}>
    {#if $selected_org.avatar_url}
      <!-- svelte-ignore a11y_missing_attribute -->
      <img src={$selected_org.avatar_url} class="w-5" />
    {:else}
      <FileQuestion class="w-5" />
    {/if}
    <span class={btnTextClass}>{$selected_org.name}</span>
    <span class="hidden sm:inline">
      <ChevronDown class={btnChevronClass} />
    </span>
  </Button>
  <Dropdown class="w-fit" bind:open={org_dropdown_open}>
    {#each user_orgs as org}
      <DropdownItem on:click={onOrgSelection(org)}>
        <div class="flex flex-row items-center gap-3">
          <!-- svelte-ignore a11y_missing_attribute -->
          {#if org.avatar_url}
            <img src={org.avatar_url} class="w-6" />
          {:else}
            <FileQuestion class="w-6" />
          {/if}
          {org.name}
        </div>
      </DropdownItem>
    {/each}
    <DropdownDivider />
    <DropdownItem on:click={onNewOrgRequested}>Add New...</DropdownItem>
  </Dropdown>
{:else}
  <Button size="sm" on:click={onNewOrgRequested} class="py-1 min-w-32">
    Add Organization
  </Button>
{/if}

<InstallGithubAppModal bind:open={new_org_modal_open} />
