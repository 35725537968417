<script lang="ts">
  import { type RichUser } from "$lib/load_helpers"

  import { page } from "$app/state"
  import { Icons } from "$lib/components/centralci"
  import { ThemeSwitch } from "svelte-ux"

  import {
    Button,
    Navbar,
    NavBrand,
    NavLi,
    NavUl,
    NavHamburger,
    A,
    Kbd,
  } from "flowbite-svelte"

  import ServiceStatusIndicator from "./service-status-indicator.svelte"
  import OrgSelection from "./org-selection.svelte"
  import UserAvatar from "./user-avatar.svelte"
  import SupportMenu from "./support-menu.svelte"

  type Props = {
    rich_user: RichUser | null
    subsite?: string | null
  }
  let { rich_user = null, subsite = null }: Props = $props()

  let activeUrl = $derived(page.url.pathname)
</script>

<header
  class="border-border/40 bg-background/95 supports-[backdrop-filter]:bg-background/60 sticky top-0 z-50 w-full border-b backdrop-blur xl:px-8"
>
  <Navbar color="none" fluid={true}>
    <NavBrand href="/">
      <div class="block xl:hidden mr-2">
        <Icons.CentralCi />
      </div>

      <div class="hidden xl:block">
        <div class="block dark:hidden">
          <Icons.LogoLight />
        </div>
        <div class="hidden dark:block">
          <Icons.LogoDark />
        </div>
      </div>
    </NavBrand>
    {#if subsite}
      <div class="block mr-2">» {subsite} »</div>
      {#if rich_user}
        <OrgSelection user_orgs={rich_user.orgs} />
      {/if}
    {/if}
    <!-- svelte-ignore a11y_no_static_element_interactions -->
    <div class="flex items-center sm:order-2">
      <NavHamburger />
    </div>

    <NavUl
      {activeUrl}
      ulClass="flex flex-col px-4 mt-4 sm:flex-row sm:space-x-8 rtl:space-x-reverse sm:mt-0 sm:text-sm sm:font-medium items-center gap-1"
      class="ml-2 flex-auto"
    >
      {#if subsite}{:else}
        <!-- Main site has top links-->
        {#if rich_user}
          <NavLi activeClass="self-center" href="/console/org/overview">
            Console
          </NavLi>
        {:else}
          <NavLi activeClass="self-center">Features</NavLi>
          <!-- TODO: else features -->
        {/if}
        <NavLi activeClass="self-center" href="/docs">Docs</NavLi>
        <NavLi activeClass="self-center" href="/pricing">Pricing</NavLi>
        <NavLi activeClass="self-center" href="/blog">Blog</NavLi>
        {#if !rich_user}
          <NavLi activeClass="self-center" href="/about">About</NavLi>
        {/if}
      {/if}

      <div class="grow"></div>
      <NavLi activeClass="self-center">
        <div class="flex flex-row items-center">
          <ServiceStatusIndicator as_badge={true} />
          <ThemeSwitch class="ml-4" />
          <SupportMenu {rich_user} />
          {#if rich_user}
            <UserAvatar {rich_user} />
            <!-- <Avatar size="sm" id="avatar-menu" src={rich_user.avatar_url} /> -->
          {:else}
            <Button
              size="sm"
              color="dark"
              class="text-sm ml-4"
              href="/login/authenticate"
            >
              Log In <Kbd class="text-xs px-1 py-0 ml-2">L</Kbd>
            </Button>
            <Button size="sm" color="light" class="ml-4" href="/login/register">
              Register
            </Button>
          {/if}
        </div>
      </NavLi>
    </NavUl>
  </Navbar>
</header>
